<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-9">
          <div class="card mb-3">
            <div class="card-header">
                <h3 class="mb-0">Request Debit Card</h3>
              </div>

            <div class="card-body min-vh" v-loading="loading">
              <p class="text-xs">Request issue card information. Your request will be approve to system within one business day.</p>
              <div class="row justify-content-md-center">
                <div class="col-lg-8">

                  <el-form ref="formCardIssue" :model="form" :rules="formRule" :label-position="labelPosition" label-width="120px">
                    
                    <el-form-item label="Bankholder ID" prop="bankholderid">
                      <el-select v-model="form.bankholderid" placeholder="please select Bankholder ID" class="form-control-alternative">
                        <el-option v-for="item in bankAccount" :key="item.bankholderID" :label="item.label" :value="item.bankholderID"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label="Card Type">
                      <el-select v-model="form.cardtype" class="form-control-alternative">
                        <el-option label="Physical" value="physical"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item>
                      <el-button type="primary" @click="onSubmit" :loading="submitting"  :disabled="submitting">Confirm</el-button>
                      <el-button>Cancel</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="card mb-3">
              <div class="card-body">
                <Shortcut />
              </div> 
          </div>

        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import BankApi from "@/api/bankaccount";
import { cardRequest } from '@/api/card'
import { isMobile } from "@/utils/helpers";
import Shortcut from '@/components/MenuShortcut.vue'
export default {
  components:{
    Shortcut
  },
  computed:{
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isBusiness(){
      return this.$store.getters.business
    },
  },
  data(){
    return{
      allowCurrency: ['GBP'],
      bankAccount: [],
      loading: false,
      submitting: false,
      form:{
        bankholderid: "",
        cardtype: "physical"
      },
      formRule: {
        bankholderid: [
          {
            required: true,
            message: "Please select Bankholder ID",
            trigger: "blur",
          }
        ],
      },
    }
  },
  methods:{
    getBank(){
      this.loading = true
      BankApi.getMultiAccount().then(({result, data, message})=>{
        this.loading = false
        if(result){
          const output = []
          Object.keys(data).forEach(currency => {
            if(this.allowCurrency.includes(currency)){
              data[currency].map((item)=>{
                output.push({bankholderID: item.cardholderid, label: `${item.currency} (${item.cardholderid})`})
              })
            }
          })
          this.bankAccount = output
        }else{
          this.$message.error(message)
        }
      })
    },
    onSubmit(){
      this.$refs.formCardIssue.validate(valid=>{
        if(valid){
          this.submitting = true
          cardRequest(this.form).then(({result, message})=>{
            this.submitting = false
            if(result){
              this.$swal(`Request issue`, `Request issue card is success`, 'success').then(()=>{
                this.$router.push('/card')
              })
            }else{
              this.$swal('Message', message, 'error')
            }
          })
        }
      })
    }
  },
  created(){
    this.getBank()
  }
}
</script>

<style>

</style>